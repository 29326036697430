import { objectToCamelCase } from '@/composables/useCaseConvert'
import { $http } from '@/plugins/http'

export interface ActivityEventRequest {
  page: number
  size: number
  sortOrder: string
  startDate?: string
  endDate?: string
}

export interface ActivityEventResponseItem {
  timestamp: string
}

export interface SignatureEventUser {
  firstName: string
  lastName: string
  email: string
  mobile: string
  userId: string
  type: 'member' | 'external' | 'api' | 'missing' | 'deleted'
}

export interface SignatureEvent extends ActivityEventResponseItem {
  action: string
  quality: string
  legislation: string
  signer: SignatureEventUser
  initiator: SignatureEventUser
  id: string
  timestamp: string
}

export interface AccountEvent extends ActivityEventResponseItem {
  id: string
  timestamp: string
  action: string
  step: string
  affectedUser: SignatureEventUser
  initiatedBy: SignatureEventUser
}

export interface IdentificationEvent extends ActivityEventResponseItem {
  legislation: string
  identifiedUser: SignatureEventUser
}

export interface ActivityEventsResponse {
  totalPages: number
  totalItems: number
  currentPage: number
  size: number
  activities: ActivityEventResponseItem[]
}

export abstract class ActivityEventsRepository {
  /**
   * Get the list of activity events from server
   * Parameters:
   * - `page` - which page to return
   * - `size` - the number of items per page
   * - `start_date` and `end_date` - should get passed as YYYY-MM-DD format
   * - `sort_order` - should be `asc` or `desc` (column: date)
   */
  abstract getEvents(businessId: string, request: ActivityEventRequest): Promise<ActivityEventsResponse>
}

class MemberEventsRepository extends ActivityEventsRepository {
  async getEvents(businessId: string, request: ActivityEventRequest): Promise<ActivityEventsResponse> {
    const url =
      `/v2/businesses/${businessId}/activities/accounts?page=${request.page}&size=${request.size}&sort=${request.sortOrder}` +
      (request.startDate ? `&start_date=${request.startDate}` : '') +
      (request.endDate ? `&end_date=${request.endDate}` : '')

    const response = await $http.$get<ActivityEventsResponse>(url)
    return objectToCamelCase(response)
  }
}

class SignatureEventsRepository extends ActivityEventsRepository {
  // eslint-disable-next-line require-await,@typescript-eslint/no-unused-vars
  async getEvents(businessId: string, request: ActivityEventRequest): Promise<ActivityEventsResponse> {
    const url =
      `/v2/businesses/${businessId}/activities/signatures?page=${request.page}&size=${request.size}&sort=${request.sortOrder}` +
      (request.startDate ? `&start_date=${request.startDate}` : '') +
      (request.endDate ? `&end_date=${request.endDate}` : '')

    const response = await $http.$get<ActivityEventsResponse>(url)
    return objectToCamelCase(response)
  }
}

class IdentificationEventsRepository extends ActivityEventsRepository {
  async getEvents(businessId: string, request: ActivityEventRequest): Promise<ActivityEventsResponse> {
    const url =
      `/v2/businesses/${businessId}/activities/identifications?page=${request.page}&size=${request.size}&sort=${request.sortOrder}` +
      (request.startDate ? `&start_date=${request.startDate}` : '') +
      (request.endDate ? `&end_date=${request.endDate}` : '')

    const response = await $http.$get<ActivityEventsResponse>(url)
    return objectToCamelCase(response)
  }
}

async function getSignaturesExportCsv(
  businessId: string,
  groupBy: string,
  startDate: string,
  endDate: string
): Promise<BlobResponse> {
  const response = await $http.get(
    `/v2/businesses/${businessId}/activities/signatures/export?group_by=${groupBy}&start_date=${startDate}&end_date=${endDate}`
  )
  const data = await response.blob()
  // file name should be : signature_usage-20240113_20240122.csv or cost_centre_report-20230113_20240122.csv
  const prefix = groupBy === 'cost_centers' ? 'cost_centre_report' : 'signature_usage'
  return {
    data,
    name: `${prefix}-${startDate.replace(/-/g, '')}_${endDate.replace(/-/g, '')}.csv`,
  }
}

async function getIdentificationExportCsv(
  businessId: string,
  startDate: string,
  endDate: string
): Promise<BlobResponse> {
  const response = await $http.get(
    `/v2/businesses/${businessId}/activities/identifications/export?start_date=${startDate}&end_date=${endDate}`
  )
  const data = await response.blob()
  return {
    data,
    name: `identifications-${startDate.replace(/-/g, '')}_${endDate.replace(/-/g, '')}.csv`,
  }
}

export const signatureEventsRepository = new SignatureEventsRepository()
export const memberEventsRepository = new MemberEventsRepository()
export const identificationEventsRepository = new IdentificationEventsRepository()
export { getIdentificationExportCsv, getSignaturesExportCsv }
