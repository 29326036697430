import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import SignatureUsageTable from '@/components/billing/SignatureUsageTable.vue';
import LayoutAdmin from '@/components/layouts/LayoutAdmin.vue';
import AccountActivityTable from '~/components/activity/AccountActivityTable.vue';
import IdentificationUsageTable from '~/components/activity/IdentificationUsageTable.vue';
import { useBusinessStore } from '~/stores/business';
export default defineComponent({
  components: {
    IdentificationUsageTable: IdentificationUsageTable,
    AccountActivityTable: AccountActivityTable,
    LayoutAdmin: LayoutAdmin,
    SignatureUsageTable: SignatureUsageTable
  },
  setup: function setup() {
    var _storeToRefs = storeToRefs(useBusinessStore()),
      isIdentificationActive = _storeToRefs.isIdentificationActive;
    var selectedTab = ref('account');
    var router = useRouter();
    var route = useRoute();
    watch(function () {
      return selectedTab.value;
    }, function (tab) {
      if (router.currentRoute.query.tab === tab) return;
      void router.replace({
        query: {
          tab: tab
        }
      });
    });
    onMounted(function () {
      var _tab;
      var tab = route.query.tab;
      if (!Number.isNaN(Number(tab))) {
        /* in case we have tabs like '0', '1', or '2' */
        tab = ['account', 'signatures', 'identifications'][Number(tab)];
      }
      selectedTab.value = (_tab = tab) !== null && _tab !== void 0 ? _tab : 'account';
    });
    return {
      selectedTab: selectedTab,
      isIdentificationActive: isIdentificationActive
    };
  },
  data: function data() {
    return {
      loadingTable: true
    };
  },
  head: function head() {
    return {
      title: 'Business - Activity'
    };
  }
});